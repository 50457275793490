import React, { useState, useEffect } from "react";
import { HStack, VStack, Text, Box, IconButton } from "@chakra-ui/react";
import Element from "../element";
import { Button } from "@agnext/reactlib";
import { MdOutlineDeleteOutline } from "react-icons/md";
import {ChevronDownIcon as CollapseIcon, AddIcon} from "@chakra-ui/icons";

// Lazy load Element and Button components
// const Element = React.lazy(() => import("../element"));
// const Button = React.lazy(() => import("@agnext/reactlib").then(module => ({ default: module.Button })));

// React Icons can also be lazy-import
// const MdOutlineDeleteOutline = React.lazy(() =>
//   import("react-icons/md").then(module => ({ default: module.MdOutlineDeleteOutline }))
// );
// const MdArrowForwardIos = React.lazy(() =>
//   import("react-icons/md").then(module => ({ default: module.MdArrowForwardIos }))
// );

// Chakra icons can be lazy imports
// const CollapseIcon = React.lazy(() => import("@chakra-ui/icons").then(module => ({ default: module.ChevronDownIcon })));
// const AddIcon = React.lazy(() => import("@chakra-ui/icons").then(module => ({ default: module.AddIcon })));

//helpers
 import { createInstanceOfRepeatableElement } from "views/Workflow/helper/helper";


function Component({
  el,
  isSectionHidden,
  formData,
  handleFormDataChange,
  displayData,
  setPage,
  navIndex,
  setNavIndex,
  formErrorData,
  prevPages,
  setSkippedPages,
  page,
  handleBlur,
  // isOnline
}) {
  const isRepeatable = el.component.repeatable?.isRepeatable;
  const minEntries = el.component.repeatable?.minEntries;
  const maxEntries = el.component.repeatable?.maxEntries;
  const unlimitedEntries = el.component.repeatable?.unlimitedEntries;
  const elements = el.component.structure?.elements;

  const [repeats, setRepeats] = useState([{ isHidden: false }]);
  let initialRepeats = [];

  if (minEntries) {
    for (let i = 0; i < minEntries; i++) {
      initialRepeats.push({
        isHidden: false,
      });
    }
  } else {
    initialRepeats.push({
      isHidden: false,
    });
  }

  useEffect(() => {
    const updatedRepeats = elements.reduce((acc, element) => {
      const attachmentsLength = formData[element.id]?.attachments?.length;
      // If there are more attachments than current acc length, update repeats
      if (attachmentsLength > acc.length) {
        const newRepeats = formData[element.id].attachments.map(() => ({
          isHidden: false,
        }));
        return newRepeats;
      }
      return acc;
    }, initialRepeats);

    if (updatedRepeats.length > repeats.length) {
      setRepeats(updatedRepeats);
    }
  }, [formData]);

  const handleAddRepeats = () => {
    if (repeats.length >= maxEntries && !unlimitedEntries) return;
    setRepeats((prev) => [...prev, { isHidden: false }]);

    createInstanceOfRepeatableElement(elements, formData, handleFormDataChange);
  };

  const handleToggleHide = (index) => {
    setRepeats((prev) =>
      prev.map((repeat, i) => {
        if (i === index) {
          return { isHidden: !repeat.isHidden };
        }
        return repeat;
      })
    );
  };

  const handleDelete = (elements, index) => {
    if (repeats.length < 2 || (minEntries && repeats.length <= minEntries))
      return;
    setRepeats((prev) => {
      prev.splice(index, 1);
      return prev;
    });
    elements.forEach((element) => {
      const elementId = element.id;
      handleFormDataChange(elementId, "repeatable-delete", index);
    });
  };

 const checkBorderForValidRepCompEle = (index, formErrorData, data)=>{
    if(formErrorData[data.element.id]?.isValid === false ){
      const errorDataIndex = formErrorData[data.element.id]?.isValidRepetableIndex || [];
      if(errorDataIndex.includes(index)){
        return "1px solid #F44336";
      }
    }
    return "";
  }

  const handleValidation = (elementId, index) => {
    if(formErrorData[elementId].isValid) return;

    if(index >= 0 && formErrorData[elementId]){
      const filterIndexes = formErrorData[elementId].isValidRepetableIndex?.filter((indx)=> index !== indx)
      if(filterIndexes?.length > 0){
        formErrorData[elementId] = { ...formErrorData[elementId], isValidRepetableIndex: filterIndexes };
        return;
      }
      else{
        formErrorData[elementId] = { ...formErrorData[elementId], isValid: true, errorMessage: "", isValidRepetableIndex: filterIndexes };
        return;
      }

    }
    else if(formErrorData[elementId]) {
      formErrorData[elementId] = { isValid: true, errorMessage: "" };
      return;
    }
  }; 

  return (
    <>
      <VStack
        w="100%"
        gap="24px"
        aria-label="component"
        sx={isSectionHidden ? { display: "none" } : {}}
      >
        {isRepeatable ? (
          repeats.map((repeat, index) => (
            <VStack
              w="100%"
              key={index}
              gap={isRepeatable ? "8px" : "24px"}
              sx={
                isRepeatable
                  ? {
                      padding: "20px",
                      width: "100%",
                      border: "1.5px solid #CBD5E0",
                      borderRadius: "15px",
                      background: "#FFF",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                      position: "relative",
                    }
                  : {}
              }
              aria-label="component"
            >
              {isRepeatable && index > 0 ? (
                <HStack w="100%" justify="space-between" alignItems="center">
                  <HStack>
                    <IconButton
                      variant="ghost"
                      borderRadius="8px"
                      // isDisabled={index < 1}
                      sx={{
                        ".icon": {
                          color: "#718096",
                        },
                      }}
                      icon={
                        repeat.isHidden ? (
                          <MdArrowForwardIos
                            w="26px"
                            h="26px"
                            className="icon"
                          />
                        ) : (
                          <CollapseIcon w="26px" h="26px" className="icon" />
                        )
                      }
                      onClick={() => {
                        handleToggleHide(index);
                      }}
                    />
                    <Text>#{index}</Text>
                  </HStack>
                  <IconButton
                    variant="ghost"
                    borderRadius="8px"
                    isDisabled={minEntries && repeats.length <= minEntries}
                    sx={{
                      ".icon": {
                        color: "red.500",
                      },
                    }}
                    icon={<MdOutlineDeleteOutline size={18} className="icon" />}
                    onClick={() => {
                      handleDelete(elements, index);
                    }}
                  />
                </HStack>
              ) : (
                <></>
              )}
              {elements.map((element) => {
                return (
                  <Box
                    aria-label="element"
                    key={element.id}
                    sx={repeat.isHidden ? { display: "none" } : {}}
                    style={
                      isRepeatable
                        ? {
                            width: "100%",
                            borderRadius: "15px",
                            background: "#FFF",
                            border: formErrorData[element.id]?.isValid === false ? checkBorderForValidRepCompEle(index, formErrorData, {element}) : "",
                            padding: formErrorData[element.id]?.isValid === false ? "10px" : ""
                          }
                        : {
                            padding: "20px",
                            width: "100%",
                            border: "1.5px solid #CBD5E0",
                            borderRadius: "15px",
                            background: "#FFF",
                            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                          }
                    }
                  >
                    <Text
                      aria-label="element label"
                      color="#2D3748"
                      fontSize="16px"
                      fontWeight="500"
                      lineHeight="1.4"
                    >
                      {element.label}
                      {element.mandatory ? (
                        <span
                          style={{
                            color: "#D93025",
                            paddingLeft: "6px",
                            fontSize: "20px",
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <></>
                      )}
                    </Text>
                    <Element
                      elementType={element.htmlType}
                      formData={formData}
                      handleFormDataChange={handleFormDataChange}
                      data={{ element }}
                      setPage={setPage}
                      displayData={displayData}
                      navIndex={navIndex}
                      setNavIndex={setNavIndex}
                      formErrorData={formErrorData}
                      setSkippedPages={setSkippedPages}
                      prevPages={prevPages}
                      page={page}
                      repeatable={el.component.repeatable}
                      index={index}
                      handleBlur={handleBlur}
                      handleValidation={handleValidation}
                      // isOnline={isOnline}
                    />
                    {element?.supportingText ? (
                      <Text
                        aria-label="element label"
                        color="#718096"
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="1.4"
                      >
                        {element.supportingText}
                      </Text>
                    ) : null}
                    {/* range text  */}
                    {element?.range ? (
                      <Text
                        aria-label="element label"
                        color="#718096"
                        fontSize="12px"
                        fontWeight="400"
                        lineHeight="1.4"
                      >
                        Please Enter between {element?.range?.from} and{" "}
                        {element?.range?.to}
                      </Text>
                    ) : null}
                  </Box>
                );
              })}
            </VStack>
          ))
        ) : (
          <VStack
            w="100%"
            gap="16px"
            sx={{
              padding: "20px",
              width: "100%",
              border: "1.5px solid #CBD5E0",
              borderRadius: "15px",
              background: "#FFF",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
            }}
            aria-label="component"
          >
            {elements.map((element) => {
              return (
                <Box
                  aria-label="element"
                  key={element.id}
                  style={{
                    padding: "20px",
                    width: "100%",
                    border: formErrorData[element.id]?.isValid === false ? "1.5px solid #F44336" : "1.5px solid #CBD5E0",
                    borderRadius: "15px",
                    background: "#FFF",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Text
                    aria-label="element label"
                    color="#2D3748"
                    fontSize="16px"
                    fontWeight="500"
                    lineHeight="1.4"
                  >
                    {element.label}
                    {element.mandatory ? (
                      <span
                        style={{
                          color: "#D93025",
                          paddingLeft: "6px",
                          fontSize: "20px",
                        }}
                      >
                        *
                      </span>
                    ) : (
                      <></>
                    )}
                  </Text>
                  <Element
                    elementType={element.htmlType}
                    formData={formData}
                    handleFormDataChange={handleFormDataChange}
                    data={{ element }}
                    setPage={setPage}
                    displayData={displayData}
                    navIndex={navIndex}
                    setNavIndex={setNavIndex}
                    formErrorData={formErrorData}
                    setSkippedPages={setSkippedPages}
                    prevPages={prevPages}
                    page={page}
                    handleBlur={handleBlur}
                    handleValidation={handleValidation}
                    // isOnline={isOnline}
                  />
                  {element?.supportingText ? (
                    <Text
                      aria-label="element label"
                      color="#718096"
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="1.4"
                    >
                      {element.supportingText}
                    </Text>
                  ) : null}
                  {/* range text  */}
                  {element?.range ? (
                    <Text
                      aria-label="element label"
                      color="#718096"
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="1.4"
                    >
                      Please Enter between {element?.range?.from} and{" "}
                      {element?.range?.to}
                    </Text>
                  ) : null}
                </Box>
              );
            })}
          </VStack>
        )}
        {isRepeatable ? (
          <HStack w="100%" justify="flex-start">
            <Button
              size="md"
              borderRadius="8px"
              onClick={() => {
                handleAddRepeats(el.component.id);
              }}
              leftIcon={<AddIcon />}
              label="Add More"
              isDisabled={repeats.length >= maxEntries && !unlimitedEntries}
            />
          </HStack>
        ) : (
          <></>
        )}
      </VStack>
    </>
  );
}

export default Component;
