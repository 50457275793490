import {
  Box,
  Flex,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Select from "../Select";
import Location from "./Location";
import SelectWorkflow from "./SelectWorkflow";
import FormDetails from "./FormDetails";
import UpdateFormDetails from "./UpdateFormDetails";

const myStyles = {
  background: "white",
  boxShadow: "0px 3.5px 5.499999523162842px rgba(0, 0, 0, 0.02)",
  borderRadius: 15,
};

const flexStyles = {
  gap: "18px",
  direction: { base: "column", md: "row" },
  justifyContent: "space-between",
  width: "100%",
};

const CustomInput = ({ label, placeholder, value, type, onChange }) => {
  return (
    <Flex direction="column" gap="8px" flex={{ base: "1", md: "1" }}>
      <Text fontSize={"12px"} fontWeight={"700"}>
        {label}
      </Text>
      <Input
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        width="100%"
        alignSelf="stretch"
        focusBorderColor="#3182ce"
        onChange={(event) => onChange(event.target.value)}
        fontSize="14px"
      />
    </Flex>
  );
};

const headers = ["Form", "Select Assignee", "Due Date"];
const AddJobInfo = ({
  selectedWorkflow,
  setCurrentWorkflow,
  allWorkflows,
  locations,
  handleSelectedLocation,
  selectedLocation,
  handleJobDetails,
  jobDetails,
  clients,
  clientId,
  handleOptionChange,
  priorities,
  fetchWorkflowDetails,
  workflowData,
  assigneeList,
  taskData,
  setTaskData,
  isWorkflowChanged,
  isEditing,
  oldTasksData,
  setOldTasksData,
  dataToCompare,
  handleUpdateTask,
  openedWorkflow,
  priority,
  jobData,
}) => {
  const getClientName = useCallback(
    (id) => {
      const client = clients.find((client) => client.id === id);
      return client ? client.value : "";
    },
    [clients]
  );

  return (
    <Stack spacing={"36px"}>
      <Box sx={myStyles}>
        <Box padding={"16px 24px"}>
          <Flex direction={"column"} gap={"18px"}>
            <Text style={{ fontWeight: "700", textTransform: "capitalize" }}>
              {jobData ? jobData?.internalCode : null}
            </Text>

            <Flex sx={flexStyles}>
              <CustomInput
                label="Job Name"
                placeholder="eg. ABC Bank Inspection"
                onChange={(value) => handleJobDetails("jobName", value)}
                value={jobDetails.jobName}
              />
              <Flex
                direction={"column"}
                gap={"8px"}
                flex={{ base: "1", md: "1" }}
              >
                <Text fontSize={"12px"} fontWeight={"700"}>
                  Client*
                </Text>
                <Select
                  placeholder="Select"
                  customKey="client"
                  options={clients}
                  handleOptionChange={handleOptionChange}
                  value={getClientName(clientId)}
                />
              </Flex>
            </Flex>
            <Flex
              direction={"column"}
              gap={"8px"}
              flex={{ base: "1", md: "1" }}
            >
              <Text fontSize={"12px"} fontWeight={"700"}>
                Location*
              </Text>
              <Location
                placeholder="Select"
                locations={locations}
                selectedLocation={selectedLocation}
                handleSelectedLocation={handleSelectedLocation}
                clientId={clientId}
              />
            </Flex>
            <Flex
              direction={"column"}
              gap={"8px"}
              flex={{ base: "1", md: "1" }}
            >
              <Text fontSize={"12px"} fontWeight={"700"}>
                Priority*
              </Text>
              <Select
                placeholder="Select"
                customKey="priority"
                options={priorities}
                handleOptionChange={handleOptionChange}
                value={priority}
              />
            </Flex>
            <Flex
              direction={"column"}
              gap={"8px"}
              flex={{ base: "1", md: "1" }}
            >
              <Text fontSize={"12px"} fontWeight={"700"}>
                Any Instructions
              </Text>
              <Textarea
                onChange={(e) => {
                  e.preventDefault();
                  handleJobDetails("instructions", e.target.value);
                }}
                value={jobDetails.instructions}
              />
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box sx={myStyles}>
        <Box padding={"16px 24px"}>
          <Stack spacing="24px">
            <Flex sx={flexStyles}>
              <Flex
                direction={"column"}
                gap={"8px"}
                flex={{ base: "1", md: "1" }}
              >
                <Text fontSize={"12px"} fontWeight={"700"}>
                  Workflow*
                </Text>
                <SelectWorkflow
                  placeholder="Select"
                  selectedWorkflow={selectedWorkflow}
                  setCurrentWorkflow={setCurrentWorkflow}
                  allWorkflows={allWorkflows}
                  fetchWorkflowDetails={fetchWorkflowDetails}
                  workflowData={workflowData}
                  isWorkflowChanged={isWorkflowChanged}
                  isEditing={isEditing}
                  openedWorkflow={openedWorkflow}
                />
              </Flex>
            </Flex>

            {isEditing && !isWorkflowChanged ? (
              <>
                <Flex gap={"16px"}>
                  {headers?.map((header, index) => {
                    return (
                      <Flex key={index} flex="1" alignItems="flex-start">
                        <Text fontSize="12px" fontWeight="500">
                          {header}
                        </Text>
                      </Flex>
                    );
                  })}
                </Flex>
                <UpdateFormDetails
                  workflowData={workflowData}
                  assigneeList={assigneeList}
                  oldTasksData={oldTasksData}
                  setOldTasksData={setOldTasksData}
                  dataToCompare={dataToCompare}
                  handleUpdateTask={handleUpdateTask}
                />
              </>
            ) : (
              workflowData &&
              selectedWorkflow && (
                <>
                  <Flex gap={"16px"}>
                    {headers?.map((header, index) => {
                      return (
                        <Flex key={index} flex="1" alignItems="flex-start">
                          <Text fontSize="12px" fontWeight="500">
                            {header}
                          </Text>
                        </Flex>
                      );
                    })}
                  </Flex>
                  <FormDetails
                    workflowData={workflowData}
                    assigneeList={assigneeList}
                    setTaskData={setTaskData}
                    taskData={taskData}
                    selectedWorkflow={selectedWorkflow}
                  />
                </>
              )
            )}
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default AddJobInfo;
