import { Box, Button, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/Icons";
import { ViewReportIcon } from "components/Icons/Icons";
import { DownloadReportIcon } from "components/Icons/Icons";
import React from "react";
import { Link } from "react-router-dom";

const styles = {
   width: "280px",
   padding: "20px",
   borderRadius: "15px",
   position: "absolute",
   boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
   zIndex: "15",
};

export const ViewJobActionDropdown = ({ isOpen, onClose, selectedActionIconPosition, jobId, inspectionId, task, status, originId, customerId,onClickViewReport}) => {
   return (
      <>
         <Modal isOpen={isOpen} onClose={onClose} sx={styles}>
            <ModalContent className="action-btn-dropdown" top={selectedActionIconPosition.y + -22 + "px"} left={selectedActionIconPosition.x + -288 + "px"}>
               <Box display="flex" rowGap="20px!important" flexDirection="column">
                  {(status === "draft" || status === "todo") && (
                     <Link to={`/admin/edit-job/${inspectionId}/${jobId}?customerId=${customerId}`}>
                        <Button
                           // display={(status !== "draft" && status !== "todo") ? 'none' : 'flex'}
                           className="download-report-btn"
                           variant="outline"
                           rightIcon={<EditIcon />}
                        >
                           Edit Job
                        </Button>
                     </Link>
                  )}

                  { (status !== "todo") && <Link to={`/admin/viewresponses?workflowId=${task?.workflowId}&formId=${task?.formId}&taskId=${task?.id}&jobId=${jobId}&inspectionId=${inspectionId}&originId=${originId}`}>
                     <Button className="download-report-btn" variant="outline" rightIcon={<ViewReportIcon />}>
                        View Responses
                     </Button>
                  </Link>}
                  <Button display={status !== "approved" ? "none" : "flex"} className="download-report-btn" rightIcon={<DownloadReportIcon />} variant="outline" onClick={() => {onClickViewReport()}}>
                     Download Report
                  </Button>
               </Box>
            </ModalContent>
         </Modal>
      </>
   );
};
