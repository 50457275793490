export const createInstanceOfRepeatableElement = (elements, formData, handleFormDataChange) => {
  elements?.map((element) => {
    const elementId = element?.id;
    const elementType = element?.htmlType;
    const elementData = formData[elementId];
    const index = elementData?.attachments?.length || 0;

    switch (elementType) {
      case "textInput":
      case "textarea":
      case "numberInput":
      case "mobile":
      case "email":
        // console.log({elementId, index})
        handleFormDataChange(
          { target: { name: elementId, value: "" } },
          "repeatable",
          index + 1
        );
        break;

      case "dropdown":
      case "dropdownWithSearch":
      case "radio":
        handleFormDataChange("", "repeatableDD", elementId, index + 1);
        break;

      case "fileUpload":
        handleFormDataChange([], [], elementId, "NA", index + 1);
        break;

      // case "datepicker":
      //   const date = endOfDay(new Date()).toISOString();
      //   handleFormDataChange(date, "repeatableDate", elementId, index + 1);
      //   break;

      case "checkbox":
      case "checkboxSearch":
      case "dropdownWithMultiSelect":
        handleFormDataChange(
          JSON.stringify([]),
          "repeatableCB",
          elementId,
          "NA",
          index + 1
        );
        break;

      default:
    }
  });
};

export const checkBorderForValidRepEle = (index, formErrorData, data)=>{
  if(formErrorData[data.element.id]?.isValid === false ){
    const errorDataIndex = formErrorData[data.element.id]?.isValidRepetableIndex || [];
    if(errorDataIndex.includes(index)){
      return "1px solid #F44336";
    }
  }
  return "1px solid #E2E8F0";
}