import { DatePicker } from "@agnext/reactlib";
import Document from "assets/svg/document2.svg";
import Eye from "assets/svg/eye-icon.svg";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import Select from "../Select";

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import ViewFormModal from "./ViewFormModal";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { sub } from "date-fns";

const UpdateFormDetails = ({
  workflowData,
  assigneeList,
  oldTasksData,
  setOldTasksData,
  dataToCompare,
  handleUpdateTask,
}) => {
  const [formDates, setFormDates] = useState({});
  const [openFormId, setOpenFormId] = useState(null);
  const [datePickerWidth, setDatePickerWidth] = useState(0);

  useEffect(() => {
    const leftBox = document.querySelector(".left-box");
    const handleResize = () => {
      const leftBox = document.querySelector(".left-box");
      if (leftBox) {
        setDatePickerWidth(leftBox.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [oldTasksData, window.innerWidth]);

  const handleDateChange = (date, formId) => {
    setFormDates((prevDates) => ({
      ...prevDates,
      [formId]: date,
    }));

    const updatedTaskData = oldTasksData.map((task) => {
      if (task.formId == formId) {
        return { ...task, date };
      }
      return task;
    });
    setOldTasksData(updatedTaskData);
  };

  // const getTitle = (id) => {
  //   const form =
  //     workflowData &&
  //     workflowData.forms.find((form) => form.id == id);
  //   if (form) return form.title;
  // };

  const handleAssigneeChange = (assigneeId, formId) => {
    const updateOldTasksData = oldTasksData.map((task) => {
      if (task.formId == formId) {
        return { ...task, assigneeId };
      }
      return task;
    });
    setOldTasksData(updateOldTasksData);
  };

  const getAssigneeName = useCallback(
    (id) => {
      const assignee = assigneeList.find((assignee) => assignee.id === id);
      return assignee ? assignee.value : "";
    },
    [assigneeList]
  );

  useEffect(() => {
    if (oldTasksData) {
      const datesData = {};
      oldTasksData.forEach((task) => {
        datesData[task.formId] = task.date;
      });
      setFormDates((prevDates) => ({ ...prevDates, ...datesData }));
    }
  }, [oldTasksData]);

  const isDisabled = (formId) => {
    const updatedTask = oldTasksData.find(
      (oldTask) => oldTask.formId === formId
    );
    const toCompare = dataToCompare.find((data) => data.formId === formId);
    return (
      updatedTask.assigneeId === undefined ||
      updatedTask.assigneeId === null ||
      toCompare.assigneeId === undefined ||
      toCompare.assigneeId === null ||
      !updatedTask.assigneeId ||
      (updatedTask.assigneeId === toCompare.assigneeId &&
        updatedTask.date.toString() === toCompare.date.toString())
    );
  };
  const {
    isOpen: viewFormModalIsOpen,
    onOpen: openViewFormModal,
    onClose: closeViewFormModal,
  } = useDisclosure();
  return (
    <>
      {oldTasksData &&
        oldTasksData
          .sort((a, b) => a.order - b.order)
          .map((oldTask) => (
            <Flex key={oldTask.id} gap={"16px"}>
              <Flex gap={"16px"} width="96%">
                <Flex
                  w="32%"
                  justifyContent="space-between"
                  h="40px"
                  bg="#EDF2F7"
                  borderRadius="8px"
                >
                  <Flex w="100%">
                    <Flex w="40px" alignItems="center" justifyContent="center">
                      <Image src={Document} />
                    </Flex>
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      style={{ width: "calc(100% - 80px)", height: "40px" }}
                    >
                      <Text
                        color="#4A5568"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        word-break="break-word"
                        textOverflow="ellipsis"
                      >
                        {/* {getTitle(oldTask.formId).length > 17
                            ? getTitle(oldTask.formId).substring(0, 17) + "..."
                            : getTitle(oldTask.formId)} */}
                        {/* {getTitle(oldTask?.formId)} */}
                        {oldTask.name}
                      </Text>
                    </Flex>
                    <Flex
                      w="40px"
                      alignItems="center"
                      justifyContent="center"
                      cursor={"pointer"}
                    >
                      <Image
                        src={Eye}
                        onClick={() => {
                          setOpenFormId(oldTask.formId);
                          openViewFormModal();
                        }}
                      />
                      {viewFormModalIsOpen && (
                        <ViewFormModal
                          isOpen={viewFormModalIsOpen}
                          onClose={closeViewFormModal}
                          openFormId={openFormId}
                          formTitle={capitalizeStringUpdated(
                            // getTitle(oldTask.formId)
                            oldTask.name
                          )}
                        />
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <Box w="32%" className="left-box">
                  <Select
                    placeholder="Select"
                    customKey="assignee"
                    options={assigneeList}
                    formId={oldTask.formId}
                    handleAssigneeChange={handleAssigneeChange}
                    value={getAssigneeName(oldTask.assigneeId)}
                    isPositionTop={true}
                  />
                </Box>
                <Box w="32%">
                  <DatePicker
                    height={40}
                    headerTextSize={12}
                    width={datePickerWidth}
                    colorSelected="#fff"
                    backgroundSelected="#4FD1C5"
                    date={
                      formDates[oldTask.formId]
                        ? formDates[oldTask.formId]
                        : null
                    }
                    setDate={(date) => handleDateChange(date, oldTask.formId)}
                    minDate={sub(new Date(), { days: 1 })}
                  />
                </Box>
              </Flex>
              <Button
                colorScheme="login"
                size="md"
                borderRadius={"8px"}
                isDisabled={isDisabled(oldTask.formId)}
                onClick={() => handleUpdateTask(oldTask)}
              >
                Update
              </Button>
            </Flex>
          ))}
    </>
  );
};

export default UpdateFormDetails;
