import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  Text,
  Collapse,
  Checkbox,
  useOutsideClick,
  InputGroup,
  Input,
  IconButton,
  InputRightElement,
  CheckboxGroup,
  VStack,
  HStack,
  Badge,
  CloseButton,
  Divider,
} from "@chakra-ui/react";
import { DropdownArrowIcon, CloseIcon } from "components/Icons/Icons";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { filterArray } from "utils/utils";
const style = {
  sideDropList: {
    display: "flex",
    padding: "6px 12px",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    justifyContent: "flex-start",
  },
  dropDownBox: {
    width: "100%",
    position: "absolute",
    border: "1px solid #E2E8F0",
    borderRadius: "15px",
    background: "var(--black-amp-white-white, #FFF)",
    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
    zIndex: 3,
    padding: "0px 8px 0px 8px",
    mt: "5px"
  },
  scrollableDropdown: {
    maxHeight: "300px",
    overflowY: "auto",
    width: "100%",
  },
};

const DropDownWithMultiSelect = ({
  data,
  handleFormDataChange,
  formData,
  repeatable,
  index,
  handleBlur,
  handleValidation

}) => {
  if (repeatable?.isRepeatable) {
    const ref = React.useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [isCheckboxFocused, setIsCheckboxFocused] = useState(false);
    const repeatIndex = index;

    const values = formData[data.element.id].attachments[repeatIndex]
      ? JSON.parse(formData[data.element.id].attachments[repeatIndex])
      : [];

    const handleChange = (val) => {
      handleFormDataChange(
        JSON.stringify(val),
        "repeatableCB",
        data.element.id,
        "NA",
        repeatIndex + 1
      );
    };

    const handleFilterTextChange = (e) => {
      setFilterText(e.target.value);
    };

    // this function will be used when removing the selected options
    // const handleRemoveSelection = (val) => {
    //   const newValues = [...values].filter((currVal) => currVal !== val);
    //   handleChange(newValues);
    // };

    const handleRemoveAllSelection = () => {
      const newValues = [];
      setFilterText("");
      handleChange(newValues);
      handleBlur()
    };

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    useOutsideClick({
      ref: ref,
      handler: () => setIsOpen(false),
    });

    const options = data?.element?.properties?.options
      ? filterArray(data.element.properties.options, filterText)
      : [];

    useEffect(() => {
      handleFormDataChange(
        JSON.stringify(values),
        "repeatableCB",
        data.element.id,
        "NA",
        repeatIndex + 1
      );
    }, []);  

    return (
      <Box ref={ref} position="relative" flex={"1"}>
        <InputGroup borderRadius="15px" height="40px">
          <Input
            bg={"white"}
            fontSize={"14px"}
            _placeholder={{
              fontSize: "15px",
            }}
            value={filterText}
            placeholder={
              values?.length > 0
                ? `${values.length} selected`
                : "Select or Search Options"
            }
            onClick={() => {
              if (isOpen !== true) {
                toggleDropdown();
              }
            }}
            onChange={handleFilterTextChange}
          />
          <InputRightElement>
            <HStack justify="flex-end" w="100%" gap="1px">
              {values?.length > 0 ? (
                <IconButton
                  bg="inherit"
                  _hover={{ cursor: "pointer" }}
                  _active={{
                    bg: "inherit",
                    transform: "none",
                    borderColor: "transparent",
                  }}
                  _focus={{
                    boxShadow: "none",
                  }}
                  icon={<CloseIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveAllSelection();
                  }}
                ></IconButton>
              ) : (
                <></>
              )}
              <IconButton
                bg="inherit"
                _hover={{ cursor: "pointer" }}
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                icon={<DropdownArrowIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropdown();
                }}
              ></IconButton>
            </HStack>
          </InputRightElement>
        </InputGroup>
        <Collapse in={isOpen}>
          <Box 
          sx={style.dropDownBox}
            onBlur={() => {
              if (!isCheckboxFocused) {
                handleBlur();
              }
            }}
            onFocus={() => setIsCheckboxFocused(true)}
            onMouseLeave={() => setIsCheckboxFocused(false)}
          >
            <List sx={style.scrollableDropdown}>
              <CheckboxGroup
                name={`${data.element.id}${repeatIndex}`}
                value={values}
                onChange={(val) => {
                  handleChange(val);
                  handleValidation(data.element.id, repeatIndex);
                }}
              >
                {options?.length ? (
                  options.map((option) => {
                    const capitalizedOption = capitalizeStringUpdated(
                      option.label
                    );
                    return (
                      <ListItem
                        key={option.value}
                        sx={style.sideDropList}
                        _hover={{ background: "#F9FDFD" }}
                        background={"inherit"}
                        borderRadius={"8px"}
                        cursor="pointer"
                        onMouseEnter={() => setIsCheckboxFocused(true)}
                        onMouseLeave={() => setIsCheckboxFocused(false)}
                      >
                        <Checkbox
                          w="100%"
                          size="md"
                          colorScheme="login"
                          value={option.value}
                        >
                          {capitalizedOption}
                        </Checkbox>
                      </ListItem>
                    );
                  })
                ) : (
                  <ListItem
                    sx={style.sideDropList}
                    _hover={{ background: "#F9FDFD" }}
                    background={"inherit"}
                    borderRadius={"8px"}
                    cursor="pointer"
                  >
                    <Text>No Matching options</Text>
                  </ListItem>
                )}
              </CheckboxGroup>
            </List>
          </Box>
        </Collapse>
      </Box>
    );
  }

  // Normal dropdown with multiple select
  const ref = React.useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [isCheckboxFocused, setIsCheckboxFocused] = useState(false);

  const handleFilterTextChange = (e) => {
    setFilterText(e.target.value);
  };

  // this function will be used when removing the selected options
  // const handleRemoveSelection = (val) => {
  //   const newValues = [...formData[data.element.id].attachments].filter(
  //     (currVal) => currVal !== val
  //   );
  //   handleFormDataChange(newValues, "", data.element.id, "NA");
  // };

  const handleRemoveAllSelection = () => {
    const newValues = [];
    setFilterText("")
    handleFormDataChange(newValues, "", data.element.id, "NA");
    handleBlur()
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useOutsideClick({
    ref: ref,
    handler: () => setIsOpen(false),
  });

  const options = data?.element?.properties?.options
    ? filterArray(data.element.properties.options, filterText)
    : [];

  const selectedOptions = formData[data?.element?.id]?.attachments
    ? formData[data.element.id].attachments
    : [];

  return (
    <Box ref={ref} position="relative" flex={"1"}>
      <InputGroup borderRadius="15px" height="40px" w="100%">
        <Input
          bg={"white"}
          fontSize={"14px"}
          _placeholder={{
            fontSize: "15px",
          }}
          value={filterText}
          placeholder={
            selectedOptions?.length > 0
              ? `${selectedOptions.length} selected`
              : "Select or Search Options"
          }
          onClick={() => {
            if (isOpen !== true) {
              toggleDropdown();
            }
          }}
          onChange={handleFilterTextChange}
        />
        <InputRightElement >
          <HStack justify="flex-end" w="100%" gap="1px">
            {selectedOptions?.length > 0 ? (
              <IconButton
                bg="inherit"
                _hover={{ cursor: "pointer" }}
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                icon={<CloseIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveAllSelection();
                }}
              ></IconButton>
            ) : (
              <></>
            )}
            <IconButton
              bg="inherit"
              _hover={{ cursor: "pointer" }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<DropdownArrowIcon />}
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown();
              }}
            ></IconButton>
          </HStack>
        </InputRightElement>
      </InputGroup>
      <Collapse in={isOpen}>
        <Box 
          sx={style.dropDownBox}
          onBlur={() => {
            if (!isCheckboxFocused) {
              handleBlur();
            }
          }}
          onFocus={() => setIsCheckboxFocused(true)}
          onMouseLeave={() => setIsCheckboxFocused(false)}
        >
          {/* {selectedOptions?.length > 0 ? (
            <VStack align="flex-start" p="8px">
              <Text fontWeight="14px">Selected Option:</Text>
              <HStack flexWrap="wrap">
                {selectedOptions?.map((option, index) => {
                  const capitalizedOption = capitalizeStringUpdated(option);
                  return (
                    <Badge
                      key={index}
                      display="flex"
                      alignItems="center"
                      colorScheme="teal"
                      borderRadius="15px"
                      p="0px 8px"
                      textTransform="none"
                    >
                      {capitalizedOption}
                      <CloseButton
                        color="teal.500"
                        size="sm"
                        onClick={() => handleRemoveSelection(option)}
                      />
                    </Badge>
                  );
                })}
              </HStack>
              <Divider />
            </VStack>
          ) : (
            <></>
          )} */}
          <List sx={style.scrollableDropdown}>
            <CheckboxGroup
              name={data.element.id}
              value={formData[data.element.id].attachments || []}
              onChange={(val) => {
                handleFormDataChange(val, "", data.element.id, "NA");
                handleValidation(data.element.id);
              }}
            >
              {options?.length ? (
                options.map((option) => {
                  const capitalizedOption = capitalizeStringUpdated(
                    option.label
                  );
                  return (
                    <ListItem
                      key={option.value}
                      sx={style.sideDropList}
                      _hover={{ background: "#F9FDFD" }}
                      background={"inherit"}
                      borderRadius={"8px"}
                      cursor="pointer"
                      onMouseEnter={() => setIsCheckboxFocused(true)} // Set true when mouse enters checkbox //This prevents handleBlur to hit on each checkbox check uncheck.
                      onMouseLeave={() => setIsCheckboxFocused(false)} // Set false when mouse leaves checkbox //This prevents handleBlur to hit on each checkbox check uncheck.
                    
                    >
                      <Checkbox
                        w="100%"
                        size="md"
                        colorScheme="login"
                        value={option.value}
                      >
                        {capitalizedOption}
                      </Checkbox>
                    </ListItem>
                  );
                })
              ) : (
                <ListItem
                  sx={style.sideDropList}
                  _hover={{ background: "#F9FDFD" }}
                  background={"inherit"}
                  borderRadius={"8px"}
                  cursor="pointer"
                >
                  <Text>No Matching options</Text>
                </ListItem>
              )}
            </CheckboxGroup>
          </List>
        </Box>
      </Collapse>
    </Box>
  );
};

export default DropDownWithMultiSelect;
