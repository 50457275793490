import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "../../pages/Create/slice";
import { WorkflowManagerSaga } from "../../pages/WorkflowManager/saga";
import { userStorage } from "utils/helper";
import JSONImport from "../JSONImport";
import { useToast } from "@chakra-ui/react";
import useToastNotification from "utils/useToastNotification";

export default function WorkflowImport({ fetchWorkflows }) {
  // useInjectReducer({ key: sliceKey, reducer: reducer });
  // useInjectSaga({ key: sliceKey, saga: WorkflowManagerSaga });
  const [workflowConfig, setWorkflowConfig] = useState(null);
  const [importError, setImportError] = useState();
  const createdBy = userStorage.get()?.id;

  const dispatch = useDispatch();
  const showToast = useToastNotification();

  useEffect(() => {
    if (workflowConfig) {
      let forms = workflowConfig.forms;
      const workflow = workflowConfig.workflow;
      workflow.status = "draft";
      workflow.createdBy = createdBy;
      forms = forms.map((form) => ({
        ...form,
        form: { ...form.form, status: "draft" },
      }));

      if (!forms || !workflow) {
        setImportError("Workflow or Forms missing");
        showToast({
          message: "Workflow or Forms missing",
          status: "error",
        });
        return;
      }

      const formsData = forms.reduce((acc, cv) => {
        acc.push(cv.form);
        return acc;
      }, []);

      dispatch(
        actions.initWorkflow({
          workflowData: { workflow },
          formData: { forms: formsData },
          workflowFormData: {
            workflowForms: forms.map((form, index) => ({
              formId: form.form.id,
              workflowId: workflow.id,
              status: "draft",
              order: form.form.order ? form.form.order : index + 1,
            })),
          },
          onError: (message) => {
            showToast({
              message: "Something went wrong when importing workflow",
              status: "error",
            });
          },
          onSuccess: () => {
            forms.forEach((formConfig, index) => {
              dispatch(
                actions.postFormData({
                  data: {
                    forms: [
                      {
                        ...formConfig.form,
                        application: "",
                        createdBy,
                      },
                    ],
                    sections: formConfig.sections,
                    blocks: formConfig.blocks,
                    composites: formConfig.composites,
                    components: formConfig.components,
                  },
                  onSuccess: () => {
                    if (forms.length - 1 === index) {
                      showToast({
                        message: "Workflow Imported Successfully",
                        status: "success",
                      });

                      fetchWorkflows();
                    }
                  },
                  onError: () => {
                    if (forms.length - 1 === index) {
                      showToast({
                        message: "Something went wrong with workflow",
                        status: "error",
                      });
                    }
                  },
                })
              );
            });
          },
        })
      );
    }
  }, [workflowConfig]);
  return (
    <JSONImport
      type="workflow"
      jsonData={workflowConfig}
      setJsonData={setWorkflowConfig}
    />
  );
}
