import React, { useEffect, useState } from "react";
import { Select } from "@agnext/reactlib";

export default function Dropdown({
  data,
  formData,
  handleFormDataChange,
  repeatable,
  index,
  handleBlur,
  handleValidation
}) {
  if (repeatable?.isRepeatable) {
    const minEntries = repeatable?.minEntries;

    const initialValues = [];
    if (minEntries) {
      for (let i = 0; i < minEntries; i++) {
        initialValues.push({
          order: i + 1,
          value: "",
        });
      }
    } else {
      initialValues.push({
        order: 1,
        value: "",
      });
    }

    const [values, setValues] = useState(
      formData[data.element.id]?.attachments.length
        ? [
            ...formData[data.element.id]?.attachments.map((value, index) => ({
              order: index + 1,
              value,
            })),
          ]
        : initialValues
    );

    useEffect(() => {
      if (formData[data.element.id]?.attachments.length) {
        setValues(
          formData[data.element.id]?.attachments.map((value, index) => ({
            order: index + 1,
            value,
          }))
        );
      } else {
        values.forEach((value) => {
          handleFormDataChange(
            value?.value,
            "repeatableDD",
            data.element.id,
            value?.order
          );
        });
      }
    }, [formData]);

    const value = values[index];
    return (
      <Select
        value={value?.value}
        size="md"
        name={index}
        focusBorderColor="login.500"
        options={data.element.properties.options}
        onChange={(e) => {
          const val = e.target.value;
          handleFormDataChange(val, "repeatableDD", data.element.id, index + 1);
          handleValidation(data.element.id, index);
        }}
        handleOnBlur={handleBlur}
      />
    );
  }
  const [optionValue, setOptionValue] = useState(
    formData[data.element.id]?.value
  );
  useEffect(() => {
    if (optionValue !== undefined) {
      handleFormDataChange(optionValue, "", data.element.id, "NA");
    }
  }, [optionValue, data.element.id]);

  useEffect(() => {
    setOptionValue(formData[data.element.id]?.value);
  }, [data.element.id, formData]);

  return (
    <>
      {data &&
      data.element &&
      data.element.properties &&
      data.element.properties.options ? (
        <Select
          value={optionValue}
          size="md"
          name="1"
          focusBorderColor="login.500"
          options={data.element.properties.options}
          onChange={(e) => {
            setOptionValue(e.target.value)
            handleValidation(data.element.id);
          }}
          handleOnBlur={handleBlur}
        />
      ) : (
        <></>
      )}
    </>
  );
}
