import {
  Box,
  Flex,
  HStack,
  Image,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tooltip,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { LineChart } from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
import "./InsightsAndGraph.css";
import { InsightsLineChart } from "components/Charts/InsightsLineChart";
import infoIcon from "../../../../assets/svg/FiInfo.svg";
import noRecordImage from "../../../../assets/img/Empty Case.png";
const styles = {
  linegraphContainer: {
    width: "100%",
    height: "122px",
    overflow: "hidden",
    maxWidth: "100%",
  },
  title: {
    color: "#7E8DA2",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "150%",
  },
  amount1: {
    color: "#2D3748",
    fontFamily: "Inter",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%",
  },
  amount2: {
    color: "#2D3748",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
    height: "33px",
    alignContent: "flex-end",
  },
  data: {
    color: "#48BB78",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
    alignSelf: "center",
    margin: "0px",
  },
  insightsCount: {
    // minWidth: "163.48px",
    gap: "16px",
    justifyContent: "space-between",
    height: "100%",
    width: "100%",
  },
  message: {
    display: "flex",
    width: "100%",
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "15px",
  },
  image: {
    maxWidth: "100%",
    height: "110px",
    margin: "auto",
  },
};

function InsightsAndGraph({
  loading,
  percentage,
  title,
  amount,
  icon,
  labels,
  data,
  tooltipData,
  iconBg = "#4FD1C5",
  isInfoBtnActive = false,
}) {
  return (
    <>
      <HStack
        className="insightsGraphMainContainer"
        border="1px solid #E2E8F0"
        w={"100%"}
        bg={"#FFF"}
        // maxWidth={"50%"}
        borderRadius={"15px"}
        padding={"20px"}
        justifyContent={"space-between"}
      >
        <VStack
          sx={{
            ...styles.insightsCount,
            flexDirection: tooltipData ? "column-reverse" : "row",
          }}
        >
          <Stat alignSelf={"self-start"} h="100%" position="relative">
            <Flex alignItems={"center"} gap="5px">
              <StatLabel sx={styles.title}>{title}</StatLabel>
              {(isInfoBtnActive)? (

                <Tooltip
                hasArrow
                label="Live data, not affected by date filter"
                placement="top-end"
              >
                <Image src={infoIcon} h="16px" />
              </Tooltip>
                
              ) : ((amount?.val3)? 
              <Tooltip
              hasArrow
              label={`E-signatures are pending for ${amount?.val3} inspections.`}
              placement="top-end"
            >
              <Image src={infoIcon} h="16px" />
            </Tooltip>:
              <></>  )     
              }
            </Flex>
            <Flex h={"100%"} gap="2px">
              <StatNumber sx={styles.amount1}>{amount.val1}</StatNumber>
              {amount?.val2 ? (
                <StatNumber sx={styles.amount2}>
                  <span style={{ fontSize: "16px" }}>/</span> {amount?.val2}
                </StatNumber>
              ) : (
                <></>
              )}
            </Flex>
          </Stat>

          <IconBox
            h={"45px"}
            w={"45px"}
            bg={iconBg}
            my="auto"
            alignSelf={"flex-start"}
          >
            {
              <img
                src={icon}
                // style={imageStyle}
                alt={`Icon`}
              />
            }
          </IconBox>
        </VStack>
        {tooltipData ? (
          labels && labels?.length ? (
            <Box
              sx={{
                ...styles.linegraphContainer,
              }}
              className="linegraphContainer"
            >
              <InsightsLineChart
                labels={labels}
                data={data}
                showLine={false}
                tooltipData={tooltipData}
              />
            </Box>
          ) : (
            <VStack style={styles.message}>
              <img
                style={styles.image}
                src={noRecordImage}
                alt="No Records Image"
              />
              <p>No Data Available</p>
            </VStack>
          )
        ) : (
          <></>
        )}
      </HStack>
    </>
  );
}

export default InsightsAndGraph;
