import { Box, Flex, Input, Text } from "@chakra-ui/react";
import React from "react";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import Select from "views/shared/Select";

const flexStyles = {
  gap: "18px",
  flexDirection: { base: "column", md: "row" },
  justifyContent: "space-between",
  width: "100%",
};
// const countries = ["India", "UAE"];
// const city = [
//   "Mohali",
//   "chandigrah",
//   "Jalandhar",
//   "Abu Dhabi",
//   "Dubai",
//   "Sharjah",
//   "Ajman",
//   "Umm Al-Quwain",
//   "Fujairah",
// ];
// const state = [
//   "Punjab",
//   "delhi",
//   "UP",
//   "Abu Dhabi",
//   "Dubai",
//   "Sharjah",
//   "Ajman",
//   "Umm Al-Quwain",
//   "Fujairah",
// ];

const CustomInput = ({
  label,
  placeholder,
  value,
  type,
  onChange,
  isValid,
  errorMessage,
}) => {
  return (
    <Flex direction="column" gap="8px" flex={{ base: "1", md: "1" }}>
      <Text fontSize={"12px"} fontWeight={"700"}>
        {label}
      </Text>
      <Input
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        width="100%"
        alignSelf="stretch"
        focusBorderColor="#3182ce"
        onChange={(event) => onChange(event.target.value)}
        fontSize="14px"
      />
      {!isValid && (
        <Text fontSize="12px" color="red.500">
          {errorMessage}
        </Text>
      )}
    </Flex>
  );
};
const addressType = ["Permanent", "Temporary"];

const AddAdditionalAddress = ({
  customerDetails,
  handleTempAddress,
  tempAddress,
  handleOptionChange,
  country,
  additionalAddressPinIsValid,
  setAdditionalAddressPinIsValid,
  validatePINCode,
  states,
  cities,
}) => {
  return (
    <Box padding={"16px 24px"}>
      <Flex direction={"column"} gap={"18px"}>
        <Flex sx={flexStyles}>
          <Flex direction={"column"} gap={"8px"} flex={{ base: "1", md: "1" }}>
            <Text fontSize={"12px"} fontWeight={"700"}>
              Address Type*
            </Text>
            <Select
              placeholder={"Select"}
              options={addressType}
              customKey="type"
              value={tempAddress.type}
              handleOptionChange={handleOptionChange}
            />
          </Flex>
          <CustomInput
            label="Address Line 1*"
            placeholder="eg. 1234 Design Avenue"
            value={tempAddress?.addressLine1}
            onChange={(value) => handleTempAddress("addressLine1", value)}
          />
          <CustomInput
            label="Address Line 2"
            placeholder="eg. Near ABC Shop"
            value={tempAddress?.addressLine2}
            onChange={(value) => handleTempAddress("addressLine2", value)}
          />
        </Flex>
        <Flex sx={flexStyles}>
          <Flex direction={"column"} gap={"8px"} flex={{ base: "1", md: "1" }}>
            <Text fontSize={"12px"} fontWeight={"700"}>
              State*
            </Text>
            <Select
              placeholder={"Select"}
              options={customerDetails?.country ? [...states].sort() : []}
              customKey="state"
              value={capitalizeStringUpdated(tempAddress?.state)}
              handleOptionChange={handleOptionChange}
              check={
                customerDetails?.country ? " " : "Please select country first"
              }
            />
          </Flex>
          <Flex direction={"column"} gap={"8px"} flex={{ base: "1", md: "1" }}>
            <Text fontSize={"12px"} fontWeight={"700"}>
              City*
            </Text>
            <Select
              customKey="city"
              placeholder={"Select"}
              options={
                customerDetails?.country && tempAddress?.state ? [...cities].sort() : []
              }
              value={capitalizeStringUpdated(tempAddress?.city)}
              handleOptionChange={handleOptionChange}
              check={
                customerDetails?.country && tempAddress?.state
                  ? " "
                  : "Please select state first"
              }
            />
          </Flex>
        </Flex>
        {country === "India" && (
          <CustomInput
            label="Pin Code*"
            placeholder="eg. 160XXX"
            value={tempAddress?.postalCode || ""}
            onChange={(value) => {
              setAdditionalAddressPinIsValid(validatePINCode(value));
              handleTempAddress("postalCode", value);
            }}
            type={"number"}
            isValid={additionalAddressPinIsValid}
            errorMessage="Please enter a valid Pin Code"
          />
        )}
      </Flex>
    </Box>
  );
};

export default AddAdditionalAddress;
