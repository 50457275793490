export const userStorage = {
  get: () => {
    const local = JSON.parse(localStorage?.getItem("inspectionDashboard"));
    return local ? local?.user : null;
  },
  set: (user) => {
    localStorage?.setItem("inspectionDashboard", JSON.stringify(user));
  },
  remove: () => {
    localStorage?.removeItem("inspectionDashboard");
  },
};

export const tokenExpiry = {
  get: () => {
    const loginObj = JSON.parse(localStorage.getItem("inspectionDashboard"));
    return loginObj ? loginObj.expiry : null;
  },
};

export const authToken = {
  get: () => {
    const loginObj = JSON.parse(localStorage.getItem("inspectionDashboard"));
    return loginObj ? loginObj.token : null;
  },
};

// export const token = {
//   get: () => {
//     const token = JSON.parse(localStorage.getItem("session"));
//     return token;
//   },
//   set: (token) => {
//     localStorage.setItem("session", JSON.stringify(token));
//   },
//   remove: () => {
//     localStorage.removeItem("session");
//   },
// };

// export const getCustomerId = () => {
//   const user = JSON.parse(localStorage.getItem("user"));
//   return user && user.customer_id;
// };

// export const getUserId = () => {
//   const user = JSON.parse(localStorage.getItem("user"));
//   return user && user.user_id;
// };

//  this commented is not remove due to future reference it will removed once sanity testing is done
// export const parseRow = (str) => {
//   const arr = [];
//   let quote = false; // 'true' means we're inside a quoted field
//   for (let row = 0, col = 0, c = 0; c < str.length; c++) {
//     let cc = str[c],
//       nc = str[c + 1]; // Current character, next character
//     arr[row] = arr[row] || []; // Create a new row if necessary
//     arr[row][col] = arr[row][col] || ""; // Create a new column (start with empty string) if necessary

//     // If the current character is a quotation mark, and we're inside a
//     // quoted field, and the next character is also a quotation mark,
//     // add a quotation mark to the current column and skip the next character
//     if (cc == '"' && quote && nc == '"') {
//       arr[row][col] += cc;
//       ++c;
//       continue;
//     }

//     // If it's just one quotation mark, begin/end quoted field
//     if (cc == '"') {
//       quote = !quote;
//       continue;
//     }

//     // If it's a comma and we're not in a quoted field, move on to the next column
//     if (cc == "," && !quote) {
//       ++col;
//       continue;
//     }

//     // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
//     // and move on to the next row and move to column 0 of that new row
//     if (cc == "\r" && nc == "\n" && !quote) {
//       ++row;
//       col = 0;
//       ++c;
//       continue;
//     }

//     // If it's a newline (LF or CR) and we're not in a quoted field,
//     // move on to the next row and move to column 0 of that new row
//     if (cc == "\n" && !quote) {
//       ++row;
//       col = 0;
//       continue;
//     }
//     if (cc == "\r" && !quote) {
//       ++row;
//       col = 0;
//       continue;
//     }

//     // Otherwise, append the current character to the current column
//     arr[row][col] += cc;
//   }
//   // console.log(arr);
//   return arr.flat();
// };


export const parseRow = (str) => {
  const rows = [];
  let isQuoted = false;
  let currentRow = [];
  let currentField = "";

  const appendField = () => {
    currentRow.push(currentField);
    currentField = "";
  };

  const newRow = () => {
    rows.push(currentRow);
    currentRow = [];
  };

  for (let i = 0; i < str.length; i++) {
    const currentChar = str[i];
    const nextChar = str[i + 1];

    // Handle double quotation marks within a quoted field
    if (currentChar === '"' && isQuoted && nextChar === '"') {
      currentField += '"';
      i++; // Skip the next quote
      continue;
    }

    // Toggle quoted field state
    if (currentChar === '"') {
      isQuoted = !isQuoted;
      continue;
    }

    // Handle field separation by comma if not in a quoted field
    if (currentChar === "," && !isQuoted) {
      appendField();
      continue;
    }

    // Handle newline characters (CRLF or LF) if not in a quoted field
    if (
      (currentChar === "\r" && nextChar === "\n" && !isQuoted) ||
      (currentChar === "\n" && !isQuoted) ||
      (currentChar === "\r" && !isQuoted)
    ) {
      appendField();
      newRow();
      if (currentChar === "\r" && nextChar === "\n") i++; // Skip the extra character for CRLF
      continue;
    }

    // Append the current character to the current field
    currentField += currentChar;
  }

  // Append the last field and row if not done
  appendField();
  newRow();

  return rows;
};


export const validateEmail = (email, allowedDomains) => {
  if(!email.trim()) return { isValid: true, message: "" };

  const emailPattern = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;

  // Check if email matches the basic pattern
  if (!emailPattern.test(email)) {
      return { isValid: false, message: 'Invalid email format.' };
  }

  // Extract domain from email
  const emailDomain = email.split('@')[1];

  if(allowedDomains){
    const isDomainAllowed = allowedDomains
    .map(domain => domain.replace(/^.*@/, '')) // Remove anything before '@'
    .includes(emailDomain);

    if (allowedDomains?.length && !isDomainAllowed) {
      return { isValid: false, message: 'Email domain is not allowed.' };
  }
  }
  

  return { isValid: true, message: "" };
}

export const validateMobileNumber = (phone, countryCode) => {
  if (!countryCode) {
    return { isValid: false, message: "Please select a country code." };
  }

  if (!phone?.trim()) return { isValid: true, message: "" };

  // Patterns for different countries
  const patterns = {
    "+91": /^[6-9]\d{9}$/, // India: Must start with 6-9 and be 10 digits
    "91" : /^[6-9]\d{9}$/,
    "+971": /^[0-9]{9}$/,   // UAE: Must be exactly 9 digits
    "971": /^[0-9]{9}$/

  };

  const pattern = patterns[countryCode];

  if (pattern) {
    // Validate the phone number against the country's pattern
    if (!pattern.test(phone)) {
      return { isValid: false, message: `Invalid mobile number for ${countryCode}.` };
    }
  } else {
    // If no validation pattern is available for the country code
    return { isValid: false, message: `Country code ${countryCode} not supported.` };
  }

  return { isValid: true, message: "" };
};

// Defined the allowed MIME types for the file uploads
export const validMimeTypesForFileUploads = {
  doc: ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
  image: ["image/*"],
  pdf: ["application/pdf"],
  spreadSheet: [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv"
  ],
  vid: ["video/*"],
  all: ["*/*"]
};

// validation for text input
export const validateText = (text, textProperties) => {
  const {
    isNumbersAllowed = false,
    isSpecialCharAllowed = false,
    minCharLength,
    maxCharLength,
  } = textProperties || {};

  if (!text.trim()) return { isValid: true, message: "" };

  const numberPattern = /[0-9]/;
  const specialCharPattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~]/;

  if (!isNumbersAllowed && numberPattern.test(text)) {
    return { isValid: false, message: "Numbers are not allowed." };
  }

  if (!isSpecialCharAllowed && specialCharPattern.test(text)) {
    return { isValid: false, message: "Special characters are not allowed." };
  }

  // Build pattern dynamically based on allowed characters
  let pattern = "a-zA-Z ";
  if (isNumbersAllowed) {
    pattern += "0-9";
  }
  if (isSpecialCharAllowed) {
    pattern += "!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?`~";
  }

  const textPattern = new RegExp(`^[${pattern}]+$`);

 if (!textPattern.test(text)) {
    return { isValid: false, message: "Invalid characters used." };
  }

  // Check for minimum character length
  if (minCharLength && text.length < minCharLength) {
    return {
      isValid: false,
      message: `Minimum ${minCharLength} characters required.`,
    };
  }

  // Check for maximum character length
  if (maxCharLength && text.length > maxCharLength) {
    return {
      isValid: false,
      message: `Maximum ${maxCharLength} characters allowed.`,
    };
  }

  return { isValid: true, message: "" };
};

export const onlyNumber = (e) =>
  (e.target.value = e.target.value.replace(/[^0-9]/g, ""));

export const parseData = (data) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
}

