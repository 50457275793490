import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectDomain = (state) => state.homePage || initialState;

export const selectFormLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectFormId = createSelector(
  [selectDomain],
  (state) => state.form && state.form.id
);

export const selectReviewData = createSelector(
  [selectDomain],
  (state) => state.reviewData
);

export const selectLastUpdatedPages = createSelector(
  [selectDomain],
  (state) => state.taskDetail && state.taskDetail.pageInfo
);

export const selectSections = createSelector(
  [selectDomain],
  (state) => state.sections
);

export const selectForm = createSelector([selectDomain], (state) => state.form);

export const selectSectionsForBlocks = createSelector(
  [selectDomain],
  (state) => state.sections || []
);

export const selectBlocksForComposites = createSelector(
  [selectDomain],
  (state) => state.blocks || []
);

export const selectCompositesForComponents = createSelector(
  [selectDomain],
  (state) => state.composites || []
);

export const selectComponentsForElements = createSelector(
  [selectDomain],
  (state) => state.components || []
);

export const selectSectionList = createSelector(
  [selectDomain],
  (state) =>
    state.sections &&
    [...state.sections].reduce((acc, cv) => {
      const newObj = { value: cv.id, label: cv.title };
      acc.push(newObj);
      return acc;
    }, [])
);

export const selectBlockList = createSelector(
  [selectDomain],
  (state) =>
    state.blocks &&
    [...state.blocks].reduce((acc, cv) => {
      const newObj = { value: cv.id, label: cv.title };
      acc.push(newObj);
      return acc;
    }, [])
);

export const selectCompositeList = createSelector(
  [selectDomain],
  (state) =>
    state.composites &&
    [...state.composites].reduce((acc, cv) => {
      const newObj = { value: cv.id, label: cv.title };
      acc.push(newObj);
      return acc;
    }, [])
);

export const selectComponentList = createSelector(
  [selectDomain],
  (state) =>
    state.components &&
    [...state.components].reduce((acc, cv) => {
      const newObj = { value: cv.id, label: cv.title };
      acc.push(newObj);
      return acc;
    }, [])
);

export const selectFilesUploadLoader = createSelector(
  [selectDomain],
  (state) => state.filesUploadLoader || {}
);

export const selectFilesUploadError = createSelector(
  [selectDomain],
  (state) => state.filesUploadError || {}
);

export const filesUploadLoader = createSelector(
  [selectDomain],
  (state) => state.uploadLoader
);
