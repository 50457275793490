import React from "react";
import Component from "../component";
// const Component = React.lazy(() => import("../component"));


export default function Components({
  data,
  isSectionHidden,
  formData,
  handleFormDataChange,
  displayData,
  setPage,
  navIndex,
  setNavIndex,
  formErrorData,
  prevPages,
  setSkippedPages,
  page,
  handleBlur,
}) {
  return (
    <>
      {data.map((el, index) => {
        return (
          <Component
            key={index}
            el={el}
            isSectionHidden={isSectionHidden}
            formData={formData}
            handleFormDataChange={handleFormDataChange}
            displayData={displayData}
            setPage={setPage}
            navIndex={navIndex}
            setNavIndex={setNavIndex}
            formErrorData={formErrorData}
            prevPages={prevPages}
            setSkippedPages={setSkippedPages}
            page={page}
            handleBlur={handleBlur}
            // isOnline={isOnline}
          />
        );
      })}
    </>
  );
}
