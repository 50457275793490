import { createSlice } from "@reduxjs/toolkit";
import { formatConfig } from "masterConfig";

export const initialState = {
  clientStatisticsLoading: false,
  clientStatisticsData: null,
  inspectionTypes: [],
  entityProfileImgUrls: [],
  masterConfig: null,
  analyticsLoading: false,
  userContext: null,
  inspectionsCompletedDataLoading: false,
  sitesInspectedDataLoading: false,
  clientsInspectedDataLoading: false,
  agencyAnalyticsDataLoading: false,
  analyticsLoading: false,
  userContextLoading: false,
  barChartLoading: false,
  doughnutChartLoading: false,
  quantityInsightsLoading: false,
  warehouseInsightsLoading: false,
  portfolioLineChartLoading: false,
  inspectionsLineChartLoading: false,
  quantityLineChartLoading: false,
  mapChartLoading: false,
  commoditiesLoading: false,
  warehouseScore: null,
  warehousesData: null,
  commoditiesData: null,
  portfolioLineChartData: null,
  inspectionsLineChartData: null,
  quantityLineChartData: null,
  quantityInsightsData: null,
  warehouseInsightsData: null,
  doughnutChartData: null,
  analyticsData: null,
  barChartData: null,
  mapChartData: null,
  error: null,
  clientsOfCustomer: null,
  customerUsers: null,
  inspectionsCompletedData: null,
  sitesInspectedData: null,
  clientsInspectedData: null,
  agencyAnalyticsData: null,
  agencyBarChartDataLoading: false,
  agencyBarChartData: null,
  approvedInspectionCountLoading: false,
  approvedInspectionCount: null,
  agencyGeographicalReachDataLoading: false,
  agencyGeographicalReachData: null,
  signaturePendingCount: null
};

const analyticsDashboardSlice = createSlice({
  name: "analyticsDashboard",
  initialState,
  reducers: {
    fetchEntityProfileImgURL: (state, action) => {
      state.loading = true;
    },
    fetchEntityProfileImgURLSuccess: (state, action) => {
      state.loading = false;
      state.entityProfileImgUrls = action.payload;
    },
    fetchEntityProfileImgURLFailure: (state, action) => {
      state.loading = false;
    },
    fetchClientStatisticsData(state, action) {
      state.clientStatisticsLoading = true;
    },
    fetchClientStatisticsDataSuccess(state, action) {
      state.clientStatisticsLoading = false;
      // console.log({clientData: action.payload});
      state.clientStatisticsData = action.payload;
    },
    fetchClientStatisticsDataFailure(state, action) {
      state.clientStatisticsLoading = false;
    },
    fetchUserContext(state, action) {
      state.loading = true;
    },
    fetchUserContextSuccess(state, action) {
      state.loading = false;
      state.masterConfig = action.payload[0]?.config?.weblayout;
      state.inspectionTypes = action.payload[0]?.enabledInspectionTypes;
    },
    fetchUserContextFailure(state, action) {
      state.loading = false;
    },
    fetchAnalyticsData(state, action) {
      state.analyticsLoading = true;
    },
    fetchAnalyticsDataSuccess(state, action) {
      state.analyticsData = action.payload;
      state.analyticsLoading = false;
    },
    fetchAnalyticsDataFailure(state, action) {
      state.analyticsLoading = false;
      state.error = true;
    },
    fetchBarChartData(state, action) {
      state.barChartLoading = true;
    },
    fetchBarChartDataSuccess(state, action) {
      state.barChartData = action.payload;
      state.barChartLoading = false;
    },
    fetchBarChartDataFailure(state, action) {
      state.barChartLoading = false;
      state.error = true;
    },
    fetchDoughnutChartData(state, action) {
      state.doughnutChartLoading = true;
    },
    fetchDoughnutChartDataSuccess(state, action) {
      state.doughnutChartData = action.payload;
      state.doughnutChartLoading = false;
    },
    fetchDoughnutChartDataFailure(state, action) {
      state.doughnutChartLoading = false;
      state.error = true;
    },
    fetchQuantityInsightsData(state, action) {
      state.quantityInsightsLoading = true;
    },
    fetchQuantityInsightsDataSuccess(state, action) {
      state.quantityInsightsData = action.payload;
      state.quantityInsightsLoading = false;
    },
    fetchQuantityInsightsDataFailure(state, action) {
      state.quantityInsightsLoading = false;
      state.error = true;
    },
    fetchWarehouseInsightsData(state, action) {
      state.warehouseInsightsLoading = true;
    },
    fetchWarehouseInsightsDataSuccess(state, action) {
      state.warehouseInsightsData = action.payload;
      state.warehouseInsightsLoading = false;
    },
    fetchWarehouseInsightsDataFailure(state, action) {
      state.warehouseInsightsLoading = false;
      state.error = true;
    },
    fetchPortfolioLineChartData(state, action) {
      state.portfolioLineChartLoading = true;
    },
    fetchPortfolioLineChartDataSuccess(state, action) {
      state.portfolioLineChartData = action.payload;
      state.portfolioLineChartLoading = false;
    },
    fetchPortfolioLineChartDataFailure(state, action) {
      state.portfolioLineChartLoading = false;
      state.error = true;
    },
    fetchInspectionsLineChartData(state, action) {
      state.inspectionsLineChartLoading = true;
    },
    fetchInspectionsLineChartDataSuccess(state, action) {
      state.inspectionsLineChartData = action.payload;
      state.inspectionsLineChartLoading = false;
    },
    fetchInspectionLineChartDataFailure(state, action) {
      state.inspectionsLineChartLoading = false;
      state.error = true;
    },
    fetchQuantityLineChartData(state, action) {
      state.quantityLineChartLoading = true;
    },
    fetchQuantityLineChartDataSuccess(state, action) {
      state.quantityLineChartData = action.payload;
      state.quantityLineChartLoading = false;
    },
    fetchQuantityLineChartDataFailure(state, action) {
      state.quantityLineChartLoading = false;
      state.error = true;
    },
    fetchMapChartData(state, action) {
      state.mapChartLoading = true;
    },
    fetchMapChartDataSuccess(state, action) {
      state.mapChartData = action.payload;
      state.mapChartLoading = false;
    },
    fetchMapChartDataFailure(state, action) {
      state.mapChartLoading = false;
      state.error = true;
    },
    fetchCommoditiesData(state, action) {
      state.commoditiesLoading = true;
    },
    fetchCommoditiesDataSuccess(state, action) {
      state.commoditiesData = action.payload;
      state.commoditiesLoading = false;
    },
    fetchCommoditiesDataFailure(state, action) {
      state.commoditiesLoading = false;
      state.error = true;
    },
    fetchWarehouses(state, action) {
      state.loading = true;
    },
    fetchWarehousesSuccess(state, action) {
      state.warehousesData = action.payload;
      state.loading = false;
    },
    fetchWarehousesFailure(state, action) {
      state.loading = false;
    },
    fetchWarehouseScore(state, action) {
      state.loading = true;
    },
    fetchWarehouseScoreSuccess(state, action) {
      state.warehouseScore = action.payload.warehouseScore;
      state.loading = false;
    },
    fetchWarehouseScoreFailure(state, action) {
      state.loading = false;
    },
    fetchClientsOfCustomer: (state, action) => {
      state.loading = true;
    },
    fetchClientsOfCustomerSuccess: (state, action) => {
      state.loading = false;
      const clientList = _.cloneDeep(action.payload)
      state.clientsOfCustomer = clientList.filter(client => client.isActive).sort((a, b) => a.clientName.localeCompare(b.clientName));
    },
    fetchClientsOfCustomerFailure: (state, action) => {
      state.loading = false;
    },
    clearState(state, action) {},
    clearStateSuccess(state, action) {
      state.warehouseScore = null;
      state.warehousesData = null;
      state.commoditiesData = null;
      state.portfolioLineChartData = null;
      state.inspectionsLineChartData = null;
      state.quantityLineChartData = null;
      state.quantityInsightsData = null;
      state.warehouseInsightsData = null;
      state.doughnutChartData = null;
      state.analyticsData = null;
      state.barChartData = null;
      state.mapChartData = null;
    },
    clearStateFailure(state, action) {},
    fetchCustomerUsersByRole: (state, action) => {
      state.loading = true;
    },
    fetchCustomerUsersSuccess: (state, action) => {
      state.loading = false;
      const customerUserByRole = _.cloneDeep(action.payload)
      state.customerUsers =customerUserByRole.filter(user => user.isActive)
    },
    fetchCustomerUsersFailure: (state, action) => {
      state.loading = false;
    },
    fetchInspectionsCompletedData: (state, action) => {
      state.inspectionsCompletedDataLoading = true;
    },
    fetchInspectionsCompletedDataSuccess: (state, action) => {
      state.inspectionsCompletedDataLoading = false;
      state.inspectionsCompletedData = action.payload;
    },
    fetchInspectionsCompletedDataFailure: (state, action) => {
      state.inspectionsCompletedDataLoading = false;
    },
    fetchSitesInspectedData: (state, action) => {
      state.sitesInspectedDataLoading = true;
    },
    fetchSitesInspectedDataSuccess: (state, action) => {
      state.sitesInspectedDataLoading = false;
      state.sitesInspectedData = action.payload;
    },
    fetchSitesInspectedDataFailure: (state, action) => {
      state.sitesInspectedDataLoading = false;
    },

    fetchClientsInspectedData: (state, action) => {
      state.clientsInspectedDataLoading = true;
    },
    fetchClientsInspectedDataSuccess: (state, action) => {
      state.clientsInspectedDataLoading = false;
      state.clientsInspectedData = action.payload;
    },
    fetchClientsInspectedDataFailure: (state, action) => {
      state.clientsInspectedDataLoading = false;
    },

    fetchAgencyAnalyticsData: (state, action) => {
      state.agencyAnalyticsDataLoading = true;
    },
    fetchAgencyAnalyticsDataSuccess: (state, action) => {
      state.agencyAnalyticsDataLoading = false;
      state.agencyAnalyticsData = action.payload;
    },
    fetchAgencyAnalyticsDataFailure: (state, action) => {
      state.agencyAnalyticsDataLoading = false;
    },
    fetchAgencyBarChartData: (state, action) => {
      state.agencyBarChartDataLoading = true;
    },
    fetchAgencyBarChartDataSuccess: (state, action) => {
      state.agencyBarChartDataLoading = false;
      state.agencyBarChartData = action.payload;
    },
    fetchAgencyBarChartDataFailure: (state, action) => {
      state.agencyBarChartDataLoading = false;
    },
    fetchApprovedInspectionCount: (state, action) => {
      state.approvedInspectionCountLoading = true;
    },
    fetchApprovedInspectionCountSuccess: (state, action) => {
      state.approvedInspectionCountLoading = false;
      state.approvedInspectionCount = action.payload.totalItems;
      state.signaturePendingCount = action.payload.signaturePending;
    },
    fetchApprovedInspectionCountFailure: (state, action) => {
      state.approvedInspectionCountLoading = false;
    },
    fetchAgencyGeographicalReachData: (state, action) => {
      state.agencyGeographicalReachDataLoading = true;
    },
    fetchAgencyGeoReachDataDataSuccess: (state, action) => {
      state.agencyGeographicalReachDataLoading = false;
      state.agencyGeographicalReachData = action.payload;
    },
    fetchAgencyGeoReachDataDataFailure: (state, action) => {
      state.agencyGeographicalReachDataLoading = false;
    },
    clearState: (state, action) => {
      // state.clientStatisticsData = null;
      // state.inspectionTypes = [];
      // state.entityProfileImgUrls = [];
      state.masterConfig = null;
      // state.userContext = null;
      // state.warehouseScore = null;
      // state.warehousesData = null;
      // state.commoditiesData = null;
      // state.portfolioLineChartData = null;
      // state.inspectionsLineChartData = null;
      // state.quantityLineChartData = null;
      // state.quantityInsightsData = null;
      // state.warehouseInsightsData = null;
      // state.doughnutChartData = null;
      // state.analyticsData = null;
      // state.barChartData = null;
      // state.mapChartData = null;
      // state.error = null;
      // state.clientsOfCustomer = null;
      // state.customerUsers = null;
      // state.inspectionsCompletedData = null;
      // state.sitesInspectedData = null;
      // state.clientsInspectedData = null;
      // state.agencyAnalyticsData = null;
      // state.agencyBarChartData = null;
      // state.approvedInspectionCount = null;
      // state.agencyGeographicalReachData = null;
    },
  },
});

export const { actions, reducer, name: sliceKey } = analyticsDashboardSlice;














